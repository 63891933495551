<template>
	<div class="content_wrap">
		<div class="content_area_wrap">
			<div class="box_content_wrap">
				<div class="tit_wrap">
					<h2>검색결과</h2>
				</div>
				<SearchTab @changeGenre="changeGenre"></SearchTab>
				<div class="sub_list_box_wrap">
					<div v-for="(item, index) in itemList" :key="index" class="sub_list_box">
						<div class="list_box_img_wrap">
							<img alt="" :src="`${$image_host}/contents/${item.thumb_path}`" />
							<div class="play_img"><img alt="" src="images/icon_play.png" /></div>
							<div class="tag_wrap">
								<div class="tags">
									<div
										class="tag"
										v-for="(tag, index) in item.hash_tag.split(',').slice(0, 2)"
										:key="index"
									>
										{{ tag }}
									</div>
								</div>
								<div class="tag"><i class="bx bxs-heart"></i>{{ item.fav_cnt.toLocaleString() }}</div>
								<div class="tag2" v-if="item.evt_yn === 'Y'">이벤트중</div>
							</div>
						</div>
						<div class="list_box_text_wrap">
							<h4>{{ item.opus_title }}</h4>
							<div class="name_box_wrap">
								<ul>
									<li>
										작가 : <span>{{ item.exposure_author }}</span>
									</li>
									<li>
										성우 : <span>{{ item.exposure_voice_actor }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div v-if="search_store.not_found === true" class="search_not">
					<i class="bx bx-message-square-dots"></i>
					<dl>
						<dt>NOT FOUND</dt>
						<dd>검색결과가 없습니다. 다시 검색해 주십시오.</dd>
					</dl>
				</div>
				<Pagination
					v-if="search_store.not_found === false"
					@changePage="changePage"
					:page="page"
					:totalPage="totalPage"
				></Pagination>
				<MainEventArea></MainEventArea>
			</div>
		</div>
	</div>
</template>
<script setup>
import Pagination from '@/shared-components/Pagination.vue';
import { SearchStore } from '@/store/Search';
import { storeToRefs } from 'pinia';
import MainEventArea from '../components/home/MainEventArea.vue';
import SearchTab from '@/shared-components/SearchTab.vue';

const search_store = SearchStore();
search_store.getSubGenreItems();

const { itemList, genre, page, totalPage } = storeToRefs(search_store);

// 장르 탭 emit 함수
const changeGenre = (genre) => {
	// store.$patch({ page: 1, genre });asdas
	search_store.setGenre(genre);
	search_store.getSubGenreItems();
};
const changePage = (page) => {
	search_store.$patch({ page });
	search_store.getSubGenreItems();
};
</script>
