<template>
	<div class="tab_wrap">
		<ul>
			<li class="genre_tab active" @click="settingGenre('ALL', $event)">전체</li>
			<li class="genre_tab" @click="settingGenre('AC', $event)">
				액션<span>({{ search_store.genre_total?.AC }})</span>
			</li>
			<li class="genre_tab" @click="settingGenre('TR', $event)">
				스릴러<span>({{ search_store.genre_total?.TR }})</span>
			</li>
			<li class="genre_tab" @click="settingGenre('ML', $event)">
				멜로<span>({{ search_store.genre_total?.ML }})</span>
			</li>
			<li class="genre_tab" @click="settingGenre('RC', $event)">
				로맨틱코메디<span>({{ search_store.genre_total?.RC }})</span>
			</li>
			<li class="genre_tab" @click="settingGenre('HR', $event)">
				호러<span>({{ search_store.genre_total?.HR }})</span>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue';
import { SearchStore } from '@/store/Search';

const emits = defineEmits(['changeGenre']);
const search_store = SearchStore();

const settingGenre = (genre, event) => {
	// 기존의 active 클래스 삭제 후 클릭한 것만 active 클래스 부여
	const genreTabs = document.querySelectorAll('.genre_tab');
	genreTabs.forEach((tab) => {
		tab.classList.remove('active');
	});
	event.target.classList.add('active');

	emits('changeGenre', genre);
};
</script>

<style scoped>
.genre_tab {
	cursor: pointer;
}
</style>
