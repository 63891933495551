// 페이지네이션 구성 시 필요한 데이터 세팅
/*
    page : 현재 페이지
    totalPage : 전체 페이지
    pageInBlock : 블록당 출력하는 페이지 개수
*/
export default function (page, totalPage, pageInBlock) {
	let startPage; // 시작 페이지
	let endPage; // 끝 페이지
	let isPrevBlock; // 이후 페이지 블록 유무
	let isNextBlock; // 이전 페이지 블록 유무
	let pageList; // 페이지에 출력되는 페이지 리스트 (시작 ~ 끝 페이지 리스트화)
	/*
        현재 페이지가 7,
        전체 페이지가 12,
        블록당 페이지를 5로 가정하고 결과 주석 첨부
    */

	// 현재 페이지를 블록당 페이지로 나눈 결과값 이후 start, end 구할 때 사용
	const sTemp = page / pageInBlock; // ex) 7 / 5 = 1.4

	// 블록당 페이지와 올림한 sTemp를 곱한 뒤 블록당 페이지 -1 한 값을 빼서 start를 구함
	startPage = pageInBlock * Math.ceil(sTemp) - (pageInBlock - 1); //  ex) 5 * 2 - (5 - 1) = 6

	// 블록당 페이지와 올림한 sTemp값을 곱한 결과값 end 구할 때 사용
	const eTemp = pageInBlock * Math.ceil(sTemp);

	// 만약에 전체 페이지값이 end보다 작을 경우엔 전체 페이지값이 end가 된다
	endPage = eTemp > totalPage ? totalPage : eTemp; // ex) 12 > (5 * 2) = true --> 5 * 2 = 10

	// 이전 페이지 블록 여부는 sTemp가 1 이상일 경우 무조건 True
	isPrevBlock = sTemp > 1 ? true : false; // 1.4 = true

	// 다음 페이지 블록 여부는 전체 페이지 개수가 endPage보다 클 경우 무조건 True
	isNextBlock = totalPage > endPage ? true : false; // 12 > 10 = true

	// start 부터 end 까지의 값들을 리스트에 담는다
	pageList = [];
	for (let s = startPage; s <= endPage; s++) pageList.push(s);

	return {
		startPage,
		endPage,
		isPrevBlock,
		isNextBlock,
		pageList,
	};
}
