<template>
	<div class="pagenation_wrap">
		<ul class="pagenation">
			<li @click="changePage(pageVars.startPage - 1)" v-if="pageVars.isPrevBlock" class="page_num">
				<i class="bx bx-chevron-left"></i>
			</li>
			<li
				@click="changePage(item)"
				class="page_num"
				:class="{ active: isActive(item) }"
				v-for="(item, index) in pageVars.pageList"
				:key="index"
			>
				{{ item }}
			</li>
			<li @click="changePage(pageVars.endPage + 1)" v-if="pageVars.isNextBlock" class="page_num">
				<i class="bx bx-chevron-right"></i>
			</li>
		</ul>
	</div>
</template>

<script setup>
import setPagingVar from '@/common-methods/setPagingVar';
import { computed, defineEmits, reactive, toRefs, watch, ref } from 'vue';

const props = defineProps({
	page: Number,
	totalPage: Number,
});

const { page, totalPage } = toRefs(props);

// 템플릿에서 사용할 페이징 관련 변수
const { startPage, endPage, isPrevBlock, isNextBlock, pageList } = setPagingVar(page.value, totalPage.value, 5);
const pageVars = reactive({ startPage, endPage, isPrevBlock, isNextBlock, pageList });

// Props 값 변경 시
watch(props, (newData) => {
	const { startPage, endPage, isPrevBlock, isNextBlock, pageList } = setPagingVar(newData.page, newData.totalPage, 5);
	pageVars.startPage = startPage;
	pageVars.endPage = endPage;
	pageVars.isPrevBlock = isPrevBlock;
	pageVars.isNextBlock = isNextBlock;
	pageVars.pageList = pageList;
	console.log(pageVars);
});

const isActive = (num) => {
	return num === page.value ? true : false;
};

// emit 등록
const emits = defineEmits(['changePage']);
const changePage = (page) => {
	emits('changePage', page);
};
</script>

<style scoped>
.pagenation {
	margin-bottom: 25px;
	border: 1px dotted lightgray;
	border-radius: 12px;
}
.page_num {
	cursor: pointer;
	font-size: 0.8rem;
}
ul.pagenation li.active {
	border-radius: 50%;
	width: 20px;
	height: 25px;
	background-color: #09ca91;
	color: #fff;
	text-align: center;
}
</style>
